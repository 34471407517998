import { E_page } from "../components/comp_page";
import {
	S_board_column,
	S_board_title,
	S_board_text,
	S_text_link,
	S_board_text_alert,
} from "../default_styles";
import { E_date_mark } from "../components/comp_news_datemark";

export const E_news_005 = ({}) => {
	return (
		<E_page>
			<E_date_mark created={"2024-01-30"}></E_date_mark>
			<S_board_column>
				<S_board_title>Goal</S_board_title>
				<S_board_text>
					<p>
						I hope this guide will give you a sufficient overview of the important aspects to keep in mind when
						choosing the default controller scheme. This guide is geared towards game developers of 2D platformers.
						Although it may not be as useful for other game genres, it may help you understand the question
						'how to decide a controller scheme?'.
					</p>
					<p>
						My intention is not a one-size-fits-all solution. Instead
						I wish to dramatically hone in on the key important decisions and make your decision process more streamlined.
					</p>
					<p>
						I hope you find the following information useful. Enjoy!
					</p>
				</S_board_text>
			</S_board_column>
			<S_board_column>
				<S_board_title>The Basics</S_board_title>
				<S_board_text>
					<p>
						Provided below are some common ground rules. By establishing them first, it gives you
						a glimpse of where I am coming from.
					</p>

					<ul style={{ textAlign: "left" }}>
						<li>Let the Player Decide: Give the player the ability to change your controller settings.</li>
						<li>Support Keyboard: Maybe</li>
						<li>Platformer Type: This is a platforming game where you can move left, right, jump, and
							launch an attack.
						</li>
					</ul>
				</S_board_text>
			</S_board_column>

			<S_board_column>
				<S_board_title>Preliminaries</S_board_title>
				<S_board_text>
					The first major decision you must consider is choosing what type of game you are making,
					specifically what type of platformer. I have listed some genres, along with the decisions


					<ul style={{ textAlign: "left" }}>
						<li>Combat </li>
					</ul>
				</S_board_text>
			</S_board_column>

			<S_board_column>
				<S_board_title>Whose right?</S_board_title>
				<S_board_text>
					<p>
						Player movement maps nicely to the DPAD, or the Left Thumbstick. The correct choice
						however is to let both DPAD and the Left Thumbstick control character movement. Assume you
						will support both until the point that you strictly need more buttons.
					</p>
					<p>
						If you need more buttons you, and other developers, will most likely drop DPAD movement support.
						The reason being that the DPAD is a simple button, unlike the Left Thumbstick that provides a range
						of states, which are not easily reproducible. The DPAD is a clean, the button is down, or the button
						is up, and when you reach for more buttons for abilities you will most likely want the mechanics
						of the DPAD.
					</p>
					<p>
						Be careful of the allure of the Left Thumbstick however. Consider what and how you will map the analog
						properties of the Left Thumbstick to the keyboard.
					</p>
				</S_board_text>
			</S_board_column>
			<S_board_column>
				<S_board_title>The Jump</S_board_title>
				<S_board_text>
					<p>
						There are four main options for the jump button, the UP direction of the Left Thumbstick,
						the UP direction of the DPAD, one of the face buttons (A, X, B), or one of the Triggers.
					</p>
					<p>
						If possible avoid the first option. The reason being that a player may activate a jump while
						quickly changing facing directions. If your game plans to employ double jumping, this is a major
						mistake for a player. The solution could be to fix the threshold Y axis amount,
						but consider the following. In order to have variable jump height, that is holding the jump
						action will cause a higher jump, the thresholds for left or right must overlap with the jump
						action.
					</p>
					<p>
						For a similar reason the DPAD UP should not be jump either, it is clunky to
						hold the button, while alternating between left and right. In order to have tight controls
						the jump button should be controlled by something separately from the left or right action.
					</p>
					<p>
						For the most part the A button is the typical button that is assigned to jump.
					</p>
				</S_board_text>

			</S_board_column>

			<S_board_column>
				<S_board_title>The Specifications</S_board_title>
				<S_board_text style={{ textAlign: "left" }}>
					<ul>
						<li>Simple Button Input</li>
							<ul>
								<li>Button A</li>
								<li>Button Y</li>
								<li>Button X</li>
								<li>Button B</li>

								<li>Left Bumper</li>
								<li>Right Bumper</li>

								<li>Left Thumbstick Down</li>
								<li>Right Thumbstick Down</li>
							</ul>
						<li>Analog Input</li>
							<ul>
								<li>Left Trigger</li>
								<li>Right Trigger</li>
								<li>Left X Axis</li>
								<li>Left Y Axis</li>
								<li>Right X Axis</li>
								<li>Right Y Axis</li>
							</ul>
					</ul>
				</S_board_text>
			</S_board_column>
			<S_board_column>
				<S_board_title>How to Apply</S_board_title>
				<S_board_text>
					Apply by sending an email to{" "}
					<S_text_link href="mailto:careers@sugargenix.com">
						careers@sugargenix.com
					</S_text_link>
					.<p>Be sure to include the following:</p>
					<ol style={{ textAlign: "left" }}>
						<li>Requested Wage.</li>
						<li>Available start date.</li>
						<li>Best form of contact.</li>
						<li>Link to art work you have done or are doing.</li>
					</ol>
					<p>Submissions without art work will not be considered.</p>

					<p>
						The position is a <S_board_text_alert>part-time</S_board_text_alert>{" "}
						<S_board_text_alert>remote</S_board_text_alert> position with hourly{" "}
						<S_board_text_alert>paid</S_board_text_alert> compensation.
					</p>
				</S_board_text>
			</S_board_column>
		</E_page>
	);
};
