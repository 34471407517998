import { useEffect } from "react";
import { styled, style_border_base } from "../../default_styles";

export const E_mympheus_canvas = () => {
	var loaded = false;

	useEffect(() => {
		if (loaded == false)
		{
			console.log("Loaded script News");

			window.addEventListener("gamepadconnected", (e) => {
				const gp = navigator.getGamepads()[e.gamepad.index];
				console.log("New Gamepad connected", e.gamepad.index);
			});

			window.mympheus_module({
				canvas: (() => document.getElementById("canvas"))(),
			});
		}
		loaded = true;
	}, []);
	return (
		<div style={{ margin: "auto" }}>
			<_container id="canvas"></_container>
		</div>
	);
};

const _container = styled.canvas`
	${style_border_base}
	filter: drop-shadow(-12px 10px 10px #151515bf);
	/* width: min(80%, 400px);
	height: min(80%, 400px); */
	width: min(80%, 1440px);
	height: min(80%, 900px);
	aspect-ratio: 1440 / 900;

	border-radius: 10px;

	margin: 0px auto 70px;
	display: block;
	box-sizing: border-box;
`;
