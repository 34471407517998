import { E_page } from "../../components/comp_page";
import { _about_board } from "./about_board";

export const E_page_about = ({}) => {
	return (
		<E_page>
			<_about_board></_about_board>
		</E_page>
	);
};
